/* You can add global styles to this file, and also import other style files */

/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Nunito:wght@600&display=swap"); */

@charset "UTF-8";
@import "~noty/lib/noty.css";
@import "~noty/lib/themes/metroui.css";
@import "~noty/lib/themes/bootstrap-v4.css";
@import "~noty/lib/themes/bootstrap-v3.css";
@import "~noty/lib/themes/light.css";
@import "~noty/lib/themes/mint.css";
@import "~noty/lib/themes/nest.css";
@import "~noty/lib/themes/relax.css";
@import "~noty/lib/themes/semanticui.css";
@import "~noty/lib/themes/sunset.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0");
@import "assets/css/template.css";
@import "assets/css/bootstrap5.min.css";
/* @import "~bootstrap/dist/css/bootstrap.css"; */
@import "assets/css/fontawesome/all.min.css";
@import "assets/css/backToTop.css";
/* @import "~bootstrap-select/dist/css/bootstrap-select.min.css"; */
/* @import url("https://fonts.googleapis.com");
@import url("https://fonts.gstatic.com"); */
.noty_theme__mint.noty_bar,
.noty_theme__sunset.noty_bar,
.noty_theme__relax.noty_bar,
.noty_theme__nest.noty_bar,
.noty_theme__metroui.noty_bar,
.noty_theme__semanticui.noty_bar,
.noty_theme__light.noty_bar,
.noty_theme__bootstrap-v3.noty_bar,
.noty_theme__bootstrap-v4.noty_bar {
  border-radius: 10px;
}

body {
  /* font-family: "Montserrat", sans-serif !important; */
  font-family: "Poppins", sans-serif !important;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef !important;
  opacity: 1;
}
.red {
  color: red;
}
.back {
  border: 2px solid #005c81;
  padding: 9px 12px 9px 12px;
  /* margin: 0px; */
  border-radius: 50%;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.multiselect-dropdown .dropdown-btn .selected-item {
  max-width: fit-content !important;
}

.wdth_txtbox {
  height: 35px;
  border-radius: 5px;
}
.noty_theme__mint.noty_type__warning {
  background-color: #e0e0e0;
  border-bottom: 1px solid #fffffe;
  /* color: #fff; */
}
.btn.btn-danger.mr-1 {
  margin-left: 10px;
}

.navigation-bottom {
  padding: 0 0 0 0 !important;
  margin: 0px 10px 10px 10px !important;
}

ul.pagination {
  padding: 0 0 0 0 !important;
}

.button-scroll-to-top {
  position: fixed;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  z-index: 1030;
  right: 60px;
  bottom: 50px;
}

.button-scroll-to-top--show {
  opacity: 1;
}

.backdown {
  /* border: 2px solid #005c81; */
  /* padding: 9px 12px 9px 12px; */
  /* margin: 0px; */
  border-radius: 50%;
}
.dropdown-btn {
  background-color: #fff !important;
}

.down {
  cursor: pointer;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 5;
  padding-right: 12px;
}

.bottom {
  width: 35px;
  position: fixed;
  top: 18%;
  right: 0;
  list-style: none;
  margin: 0;
  z-index: 95;
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-top-left-radius: 0.42rem;
  border-bottom-left-radius: 0.42rem;
}

input:focus,
select:focus,
textarea:focus {
  /* outline: none; */
  border-color: #ccefed !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(204 239 237) !important;
}

.was-validated select.form-control:invalid,
select.form-control.is-invalid {
  background-position: right calc(1.375em + 0.1875rem) center;
}
