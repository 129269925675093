:root {
  --blue: #005c81;
  --blue: #005c81;
  --lightGreen: #16c098;
  --darkGreen: #008767;

  --Lightblue: #eaf7f7;
  --white: #ffffff;
  --LightGray: #f9fbff;

  --bs-font-poppins: "Poppins", sans-serif;
}

a,
*::before {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
body {
  font-family: var(--bs-font-poppins);
  background: var(--Lightblue);
}
.main {
  padding: 2%;
}

.bg {
  background: url(../images/bg.png) no-repeat left top;
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginBox {
  background-color: #fff;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  display: flex;
  justify-content: center;
  width: 442px;
  flex-direction: column;
  padding: 30px;
  text-align: center;
  margin: auto;
}
.logo {
  padding: 0 0 30px 0;
}
.logo img {
  width: 275px;
}

input[type="text"],
input[type="date"],
input[type="password"],
input[type="email"],
input[type="number"],
select,
textarea {
  background-color: #fff;
  border: solid 1px #ccefed;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 10px 17px;
  background-color: var(--LightGray) !important;
  width: 100%;
}
.formRow {
  padding-bottom: 40px;
  position: relative;
}
.passIcon {
  position: absolute;
  right: 15px;
  top: 12px;
}
.toggle-password {
  cursor: pointer;
}
.basicBtn {
  background: var(--blue) !important;
  color: var(--white) !important;
  border: none;
  padding: 12px 40px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  text-align: center;
}
.forgotpassword {
  text-align: right;
}
.btnOuter {
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 0 0;
}
.btnOuter a {
  color: #5d6175;
}
.loginBox ul li {
  list-style: none;
  padding: 7px 0;
  border-bottom: solid 1px #ccefed;
  text-align: left;
}
.loginBox ul {
  padding: 0 0 50px 0;
  margin: 0 80px 0 80px;
}
.loginBox ul li a {
  color: #005c81;
  text-decoration: none;
}

.header {
  padding: 15px;
  background: var(--white);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  display: grid;
  grid-template-columns: 150px 1fr;
  align-items: center;
}

.navbar-toggler.collapsed::before {
  content: "";
  font-family: "Material Icons";
  content: "\e5d2";
  display: inline-block;
}

.navbar-toggler {
  display: none;
}
.navbar-toggler::before {
  content: "";
  font-family: "Material Icons";
  content: "\e5cd";
  display: inline-block;
}

.navbar-toggler:focus {
  border: none;
  outline: none;
  box-shadow: none;
  border: solid 1px rgba(255, 255, 255, 0.8);
}

.logoHeader img {
  width: 140px;
}
.TopMenu {
  padding-left: 15px;
}
.navbar-nav li a.nav-link {
  color: #000;
}

.main-menu ul li {
  display: inline-block;
  position: relative;
}

.main-menu ul li:not(:first-child) {
  margin-left: 40px;
}

.main-menu ul li a {
  display: inline-block;
  /* Change Font Size By Brijesh  */
  /* font-size: 16px; */
  font-size: 13px;
  font-weight: 500;
  color: #0e1133;
  padding: 15px 0;
  text-transform: capitalize;
}
.main-menu {
  padding-left: 40px;
}
.main-menu ul {
  padding: 0 0 0 0px;
  margin: 0;
}
.main-menu ul li a {
  text-decoration: none;
}
.main-menu ul li.has-dropdown > a {
  position: relative;
}

.main-menu ul li.has-dropdown > a::after {
  content: "\e5cf";
  font-family: "Material Icons";
  display: inline-block;
  -webkit-transform: translateY(1px);
  -moz-transform: translateY(1px);
  -ms-transform: translateY(1px);
  transform: translateY(1px);
  font-size: 14px;
  color: #000;
  margin-left: 5px;
  -o-transform: translateY(1px);
}

.main-menu ul li:hover > a {
  color: #62c6c1;
}

.main-menu ul li:hover > a::after {
  color: #62c6c1;
}

.main-menu ul li:hover .submenu {
  top: 100%;
  visibility: visible;
  opacity: 1;
}

.main-menu ul li .submenu {
  position: absolute;
  top: 120%;
  left: 0;

  width: 200px;
  background: #ffffff;
  z-index: 1;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  visibility: hidden;
  opacity: 0;
  -webkit-box-shadow: 0px 30px 70px 0px rgba(11, 6, 70, 0.08);
  -moz-box-shadow: 0px 30px 70px 0px rgba(11, 6, 70, 0.08);
  box-shadow: 0px 30px 70px 0px rgba(11, 6, 70, 0.08);
}

.main-menu ul li .submenu li {
  display: block;
  width: 100%;
  margin: 0;
}

.main-menu ul li .submenu li:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.main-menu ul li .submenu li.has-dropdown > a::after {
  position: absolute;
  top: 50%;
  right: 25px;
  -webkit-transform: translateY(-50%) rotate(-90deg);
  -moz-transform: translateY(-50%) rotate(-90deg);
  -ms-transform: translateY(-50%) rotate(-90deg);
  transform: translateY(-50%) rotate(-90deg);
}

.main-menu ul li .submenu li a {
  padding: 10px 25px;
  font-size: 13px;
  position: relative;
  z-index: 1;
  color: #0e1133;
  width: 100%;
}

.main-menu ul li .submenu li:hover > a {
  color: var(--blue);
}

.main-menu ul li .submenu li:hover > a::after {
  color: #ffffff;
}

.main-menu ul li .submenu li:hover > a::before {
  left: 0;
  right: auto;
  width: 100%;
}

.main-menu ul li .submenu li:hover .submenu {
  left: 100%;
  visibility: visible;
  opacity: 1;
}

.main-menu-2 ul li:not(:first-child) {
  margin-left: 35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .main-menu-2 ul li:not(:first-child) {
    margin-left: 20px;
  }
}

.main-menu-2 ul li .menu-tag {
  position: absolute;
  top: 13px;
  right: -4px;
  display: inline-block;
  height: 20px;
  line-height: 20px;
  padding: 0 7px;
  background: #62c6c0;
  color: #ffffff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-size: 12px;
  text-transform: capitalize;
  -webkit-box-shadow: 0px 8px 20px 0px rgba(1, 23, 133, 0.3);
  -moz-box-shadow: 0px 8px 20px 0px rgba(1, 23, 133, 0.3);
  box-shadow: 0px 8px 20px 0px rgba(1, 23, 133, 0.3);
}

/* 
.main-menu ul li .submenu li a::before {
    position: absolute;
    content: "";
    top: 0;
    left: auto;
    right: 0;
    width: 0;
    height: 100%;
    background: #62C6C0;
    z-index: -1;
}

.main-menu ul li .submenu li:hover>a::before {
    left: 0;
    right: auto;
    width: 100%;
} */

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

.main-menu {
  display: flex;
  justify-content: space-between;
}

/* .main-menu ul li .submenu {
    top: 100%;
    visibility: visible;
    opacity: 1;
} */
.main-menu .myAccount ul li .submenu {
  right: -14px;
  left: initial;
}
.myAccount li a {
  display: flex !important;
  align-items: center;
}
.myAccount li img {
  padding-right: 10px;
  padding-left: 10px;
}

.listing {
  background: var(--white);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  margin-top: 25px;
  padding: 25px;
}
.topbar,
.topbar-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.topbar-2 {
  padding: 20px 0 0 0;
}

.titleHead {
  font-weight: 600;
  /* Change Font Size By Brijesh  */
  /* font-size: 22px; */
  font-size: 16px;
}
.filter {
  display: flex;
  justify-content: flex-end;
  gap: 25px;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
}
.addNew a:hover {
  opacity: 0.7;
}
.titleHead span {
  color: var();
  display: block;
  font-size: 14px;
  font-weight: normal;
}

thead tr th {
  color: var(--lightGreen);
  font-weight: normal;
}
.rowListing {
  padding: 30px 0 0 0;
}

table tbody tr:hover {
  background-color: var(--LightGray);
}
tbody td:last-child,
thead th:last-child {
  text-align: center;
}
.statusBtn {
  background-color: #a6e7d8;
  color: var(--darkGreen);
  padding: 6px 20px;
  margin: 7px 0;
  display: inline-block;
  text-align: center;
  border: solid 1px #00b087;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}

tbody td {
  vertical-align: middle;
}
.action {
  display: flex;
  justify-content: center;
  gap: 15px;
}
.form-check-input:checked {
  background-color: var(--darkGreen) !important;
}
.in-active {
  background-color: #ffc5c5;
  color: #df0404;
  border-color: #df0404;
}

/* Pagination css  */

ul.pagination {
  padding: 30px 0 !important;
  margin: 0;
}

.page-link {
  padding: 5px 15px;
  color: #25282b !important;
}

.page-item.active .page-link {
  color: #fff !important;
}
/* End  Pagination css  */
.navigation-bottom {
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
}
.shorting::after {
  padding-left: 7px !important;
  content: "\f161";
  font-weight: 900 !important;
  font-family: "Font Awesome 6 Free" !important;
}
.shorting.act::after {
  content: "\f160";
}
.perPage {
  display: flex;
  align-items: center;
}
.page-item.active .page-link {
  background-color: var(--darkGreen) !important;
  border-color: var(--darkGreen) !important;
}
.formRow label {
  display: block;
  padding-bottom: 7px;
  color: #005c81;
}
.formRow label span {
  color: red;
}

.addNewOuter {
  padding-top: 20px;
  border-top: solid 1px rgba(0, 0, 0, 0.075);
  margin-top: 20px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.searchbar {
  background: url(../images/search.png) no-repeat 18px 12px;
  padding-left: 45px !important;
}

/* Dropdown Button */
.dropbtn {
  background-color: #a6e7d8;
  color: #008767;
  padding: 12px 30px;
  font-size: 14px;
  border: solid 1px #008767 !important;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

/* Dropdown button on hover & focus */
.dropbtn:hover,
.dropbtn:focus {
  background-color: #a6e7d8;
}

/* The search field */
#UserSearch {
  box-sizing: border-box;
  background-position: 14px 12px;
  background-repeat: no-repeat;
  font-size: 16px;

  padding: 7px 20px 10px 35px;
  border: none;
  border: none;
}
#UserSearch:focus {
  border: none !important;
  outline: none !important;
}

/* The search field when it gets focus/clicked on */
#UserSearch:focus {
  outline: 3px solid #ddd;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f6f6f6;
  min-width: 200px;
  top: 118%;
  border: 1px solid #ddd;
  z-index: 1;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  font-size: 15px;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
  display: block;
}

.dropbtn::after {
  content: "";
  font-family: "Material Icons";
  content: "\e5cf";
  padding-left: 10px;
  padding-top: 7px;
}
.UserSearch {
  display: flex;
}
.UserSearch::before {
  opacity: 0.5;
  display: inline-block;
  font-family: "Material Icons";
  content: "\e8b6";
  position: absolute;
  top: 3px;
  left: 7px;
  font-size: 25px;
}
.selectUser {
  display: flex;
  gap: 20px;
}
.full-width {
  max-width: 90%;
}
td.error {
  color: red;
}
td a {
  color: var(--blue);
}
.viewOuter {
  display: grid;
  gap: 50px;
  padding-top: 30px;
  grid-template-columns: 1fr 1fr;
}
.detail-row {
  display: grid;
  gap: 20px;
  grid-template-columns: 180px 1fr;
  grid-auto-rows: 50px;
}
.label {
  opacity: 0.7;
}
.data-action {
  background: #e7f4f7;
  padding: 30px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.formTitle {
  font-size: 20px;
  font-weight: bold;
  padding: 0 0 15px 0;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #ccefed !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(204 239 237) !important;
}
.ellipsis-50 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100px;
}
.ellipsis-100 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
}
.ellipsis-200 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
}
.ellipsis-300 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
}
.ellipsis-400 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
}
select {
  font-size: small !important;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  font-size: small !important;
}
body {
  font-size: 0.8rem !important;
}
thead tr th {
  color: var(--darkGreen);
  font-weight: 600;
}

.main-menu ul li .submenu {
  position: absolute;
  top: 120%;
  left: 0;

  width: 200px;
  background: #ffffff;
  z-index: 1;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  visibility: hidden;
  opacity: 0;
  display: none;
  -webkit-box-shadow: 0px 30px 70px 0px rgba(11, 6, 70, 0.08);
  -moz-box-shadow: 0px 30px 70px 0px rgba(11, 6, 70, 0.08);
  box-shadow: 0px 30px 70px 0px rgba(11, 6, 70, 0.08);
}

.main-menu ul li:hover .submenu {
  top: 100%;
  visibility: visible;
  opacity: 1;
  display: block;
}

.main-menu ul li .submenu li {
  display: block;
  width: 100%;
  margin: 0;
}
